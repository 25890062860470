import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import "./modal.css";

const VideoModal = (props) => {
  const { modal, toggle, className, videourl } = props;

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      className={`p-0 mt-5 w-100 team_modal_container ${className}`}
      style={{ border: "none" }}
    >
      <ModalBody
        className="w-100 h-100 team_modal_body"
        style={{ border: "none" }}
      >
        <div className="p-0 m-0 animate_iframe content_container">
          <button onClick={toggle}>
            <FontAwesomeIcon className="icon mr-2 active" icon={faClose} />
          </button>
          <iframe
            style={{ position: "relative" }}
            title="45451 Drifters Reef Road"
            src={videourl}
            frameborder="0"
            //   height="489.38px"
            //   width="870px"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            className="video_container no-lazyload"
          ></iframe>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default VideoModal;
