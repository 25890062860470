import React from "react";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";

const Example = (props) => {
  return (
    <Form className="About-main-form-input mt-5 ">
      <Row form className="container-sm">
        <Col md={12} sm={12} lg={6}>
          <FormGroup>
            <Label for="exampleEmail">First Name</Label>
            <Input
              style={{ border: "1px solid #fff", borderRadius: "0" }}
              className="Form-input"
              type="text"
              name="FirstName"
              id="FirstName"
              placeholder="First Name"
            />
          </FormGroup>
        </Col>
        <Col md={12} sm={12} lg={6}>
          <FormGroup>
            <Label for="examplePassword">Last Name</Label>
            <Input
              style={{ border: "1px solid #fff", borderRadius: "0" }}
              className="Form-input LastName"
              type="text"
              name="LastName"
              id="LastName"
              placeholder="Last Name"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form className="container-sm">
        <Col md={12} sm={12} lg={6}>
          <FormGroup>
            <Label for="exampleEmail">Email</Label>
            <Input
              style={{ border: "1px solid #fff", borderRadius: "0" }}
              className="Form-input"
              type="mail"
              name="Email"
              id="Email"
              placeholder="Email"
            />
          </FormGroup>
        </Col>
        <Col md={12} sm={12} lg={6}>
          <FormGroup>
            <Label for="examplePassword">Phone</Label>
            <Input
              style={{ border: "1px solid #fff", borderRadius: "0" }}
              className="Form-input Phone"
              type="text"
              name="Phone"
              id="Phone"
              placeholder="Phone"
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup className="container-sm">
        <Label for="exampleAddress2">Subject</Label>
        <Input
          type="text"
          name="Subject"
          id="Subject"
          placeholder="Subject"
          style={{ border: "1px solid #fff", borderRadius: "0" }}
        />
      </FormGroup>
      <FormGroup className="container-sm">
        <Label for="exampleText">Message</Label>
        <Input
          type="textarea"
          name="text"
          id="Message"
          placeholder="Message"
          style={{ border: "1px solid #fff", borderRadius: "0" }}
        />
      </FormGroup>
      <FormGroup
        className="container-sm"
        style={{ display: "flex", justifyContent: "center", marginTop: "3rem" }}
      >
        <Button className="form-btn">submit now</Button>
      </FormGroup>
    </Form>
  );
};

export default Example;
