import React from "react";
import { UncontrolledCarousel } from "reactstrap";
import Img1 from "../../images/img-2.png";
import Img2 from "../../images/img-3.png";
import Img3 from "../../images/img-4.png";

const AboutSecondSection = ({ image }) => {
  const items = [
    {
      src: `${Img1}`,

      key: "1",
    },
    {
      src: `${Img2}`,

      key: "2",
    },
    {
      src: `${Img3}`,

      key: "3",
    },
  ];
  return (
    <div
      style={{
        // height: "530px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
      className="container p-5 RealEstateSection-mian"
    >
      <div
        className="row pt-4"
        style={{
          // height: "530px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          flexDirection: "row",
        }}
      >
        <div className="col-lg-6 col-sm-12 col-md-6">
          <p
            style={{ fontSize: "32px", color: "#086333", fontWeight: "400" }}
            className=""
          >
            PAMELA HUDSON REAL ESTATE
          </p>
          <p
            className=""
            style={{
              color: "gray",
              fontSize: "17px",
              textAlign: "left",
            }}
          >
            Since 1988, Pamela Hudson has ethically and responsibly represented
            thousands of clients in their finance, purchase and sale of real
            estate in the State of California. Our office is the best team of
            top-producing, expert Realtor ® on the spectacularly rugged and
            pristine Mendocino Coast. We hold the record for the highest-priced
            residential sale ever in the county. Our specialty of representing
            trophy property sellers is unmatched. We look forward to serving
            you. Please contact us today.
          </p>
        </div>
        <div className="col-lg-5 col-sm-12 col-md-6">
          <UncontrolledCarousel
            // controls={true}
            // indicators={false}
            items={items}
          />
        </div>
      </div>
      <div className="container-fluid">
        <img src={image} alt="Aboutus Image" className="aboutus-image" />
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <p
            style={{ fontSize: "36px", fontWeight: "700", color: "black" }}
            className="pl-5"
          >
            Delivering Success
          </p>
          <p
            style={{ color: "gray", fontSize: "16px", lineHeight: "1.8" }}
            className="pl-5"
          >
            Pamela Hudson Real Estate provides a consistent and intensive
            marketing plan for all our clients. This is one reason for the
            incredible success we deliver. Our exclusive relationship with the
            most talented photographer on the coast ensures every listing is
            represented in its ideal state, to inspire and attract buyers with
            beautiful professional photos, videos and marketing assets.
          </p>
          <p
            style={{ color: "gray", fontSize: "16px", lineHeight: "1.8" }}
            className="pl-5"
          >
            As well, we work with two multiple listing services, Coastal
            Mendocino Association of Realtors and Bay Area Real Estate
            Information Services, to increase our reach beyond single MLS
            listings. Additionally, we are active members of the exclusive
            service REALM, that showcases our luxury listings and our off market
            listings. REALM offers outstanding international visibility and
            access to vetted buyers. Our weekly Zoom meetings with the leaders
            and members of REALM, build upon a strong networking of real estate
            professionals from around the world, delivering insights and
            opportunities.
          </p>
          <p
            style={{ color: "gray", fontSize: "16px", lineHeight: "1.8" }}
            className="pl-5"
          >
            Our agents are Premier Agents on Zillow and have a long-standing
            relationship with the service. Our reviews are impeccable, because
            our agents provide full service, skilled representation to all our
            customers. We have a majority market share in our Mendocino ZIP Code
            and have solid presence throughout the county.
          </p>
          <p
            style={{ color: "gray", fontSize: "16px", lineHeight: "1.8" }}
            className="pl-5"
          >
            Our vendor relationships have been nurtured over time and provide
            important access to inspectors and quality services from the most
            skilled and professional companies on the coast. We provide
            references to established mortgage brokers and other lending sources
            from FHA through private money programs to ensure the financing
            success for those buyers that wish to have mortgages.
          </p>
          <p
            style={{ color: "gray", fontSize: "16px", lineHeight: "1.8" }}
            className="pl-5"
          >
            Every aspect of your experience with Pamela Hudson Real Estate is
            important to us. We are available 24 hours a day and understand that
            every phone call may produce the needed next step on our path to a
            successful transaction.
          </p>
          <br></br>

          <p
            style={{ color: "gray", fontSize: "16px", lineHeight: "1.8" }}
            className="pl-5"
          >
            We look forward to working with you. Please contact us today.
          </p>
          <br></br>
          <p
            style={{ color: "gray", fontSize: "16px", lineHeight: "1.8" }}
            className="pl-5"
          >
            Energy, Ethics, Experience!
          </p>
          <br></br>

          <p
            style={{ color: "gray", fontSize: "16px", lineHeight: "1.8" }}
            className="pl-5"
          >
            Pamela Hudson
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutSecondSection;
