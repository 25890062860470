import React, { useState } from "react";
import { SEO } from "../components";
import scrollTo from "gatsby-plugin-smoothscroll";
import Header from "../components/layout/header";
import Footer from "../components/layout/footer";
import FooterHeadline from "../components/layout/footerheadline";
import AboutUsMain from "../components/aboutus/about-us-main";
import AboutUsSecondSection from "../components/aboutus/about-us-second-section";
import MailSection from "../components/aboutus/about-us-mail-section";
import Form from "../components/aboutus/about-us-from";
import classNames from "classnames";
import { Container } from "reactstrap";
import SideBar from "../components/layout/sidebar/sidebar";
import Topbar from "../components/layout/content/topbar";
import { useInView } from "react-intersection-observer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { graphql } from "gatsby";
import Modal from "../components/aboutus/modal";

const AboutUs = ({ data }) => {
  const seo = data.sanitySeo;
  const banner = data.sanityBanners.banner.asset.url;

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [sidebarIsOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  const { ref, inView, entry } = useInView();
  const handleBrightness = () => {
    if (sidebarIsOpen) {
      return "brightness(50%)";
    } else {
      return "brightness(100%)";
    }
  };
  return (
    <div
      style={{
        maxWidth: "100vw",
        overflowX: "hidden",
      }}
    >
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ldSchema={seo.ldSchema}
      />
      <div className="App wrapper" id="top">
        <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
        <Container
          fluid
          className={classNames("p-0 m-0", { "is-open": sidebarIsOpen })}
          style={{
            filter: `${handleBrightness()}`,
          }}
        >
          <Header>
            {!sidebarIsOpen ? <Topbar toggleSidebar={toggleSidebar} /> : null}
          </Header>
          <div className="margin-class"></div>
          <AboutUsMain
            title="About Us"
            bread="ABOUT US"
            buttontext="Message from Pamela"
            src={banner}
            cover="cover"
            onClick={toggle}
            position="50% 60%"
          />
          {!!modal && (
            <Modal
              modal={modal}
              toggle={toggle}
              videourl="https://cdn.sanity.io/files/36ueq3h7/production/71e6cc3a86c980463943eaef2ca860e905447d6a.m4v"
            />
          )}
          <div
            style={{
              backgroundColor: "#f7f7f7",
            }}
          >
            <AboutUsSecondSection image="https://cdn.sanity.io/images/36ueq3h7/production/a137e88bb32eee5d2b275959e676090211968ee8-1400x933.jpg" />
          </div>
          <div ref={ref}>
            <Form />
            <MailSection />

            <Footer />
            <div
              style={{
                borderTop: "1px solid green",
              }}
            >
              <FooterHeadline />
            </div>
          </div>
        </Container>
      </div>
      {inView && (
        <div>
          <p
            className="back-to-top in scroll-up-button"
            onClick={() => {
              scrollTo("#top");
            }}
          >
            <FontAwesomeIcon
              className="scroll-up-button-icon mt-2"
              icon={faAngleUp}
            />
          </p>
        </div>
      )}
    </div>
  );
};
export default AboutUs;

export const query = graphql`
  {
    sanityBanners(id: { eq: "-1cb23944-2e5c-5899-b2fa-63bef278c340" }) {
      id
      pageTitle
      banner {
        asset {
          url
        }
      }
    }
    sanitySeo(pagehandle: { eq: "about-us" }) {
      title
      description
      keywords
      ldSchema
    }
  }
`;
