import React from "react";
import MainForm from "./form/form.";
const Form = () => {
  return (
    <div className="about-us-form pt-4">
      <p
        style={{ fontWeight: "400", fontSize: "48px", color: "white" }}
        className="mt-3 about-us-form-heading"
      >
        || CONTACT ||
      </p>
      <p
        style={{
          fontWeight: "400",
          fontSize: "14px",
          color: "white",
          textAlign: "center",
        }}
        className=" mt-4 container-sm"
      >
        TO INQUIRE ABOUT A PROPERTY OR PRESS EVENT, PLEASE FILL OUT THE FORM
        BELOW.
      </p>
      <MainForm />
    </div>
  );
};

export default Form;
